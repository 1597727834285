<template>
  <div class="detaile">
    <!--        <button class="detailedBtn"  @click="closeDetailed">返回</button>-->
    <div class="searchHeader">
      <div class="showTime">
        {{ selectTime }}
      </div>
      <div class="searchOuter">
        <div class="search">
          <div class="icon">
            <i class="el-icon-search"></i>
          </div>
          <div class="word" style="position: relative">
            <input
              @focus="getFocus"
              type="text"
              v-model="value"
              placeholder="请输入关键词搜索"
            />
            <i
              v-if="value"
              @click="clearSearch"
              class="el-icon-circle-close"
              style="
                cursor: pointer;
                position: absolute;
                top: 11px;
                right: 16px;
              "
              title="清空筛选条件"
            ></i>
          </div>
          <div class="searchBtn" @click="search">搜索</div>
        </div>
      </div>
    </div>
    <div class="header" v-if="header.length == 4">
      <div v-for="(item, index) in header" :key="index" style="width: 25%">
        {{ item }}
        <i
          class="el-icon-sort"
          v-if="index == header.length - 1"
          title="排序"
          @click="sort"
          style="cursor: pointer"
        ></i>
      </div>
    </div>
    <div class="header" v-else-if="header.length == 3">
      <div v-for="(item, index) in header" :key="index" style="width: 33%">
        {{ item }}
        <i
          class="el-icon-sort"
          v-if="index == header.length - 1"
          title="排序"
          @click="sort"
          style="cursor: pointer"
        ></i>
      </div>
    </div>
    <section class="contentArea" v-loading="searchLoading">
      <ul class="content" v-for="(item, index) in detaileData" :key="index">
        <li v-if="type == 'report_name'">
          <div style="width: 25%" v-html="item.reportName"></div>
          <!--                    模块名称-->
          <div style="width: 25%">{{ item.moduleName }}</div>
          <div style="width: 25%">{{ item.visitTime }}</div>
          <div style="width: 25%">{{ item.tops }}</div>
        </li>
        <li v-if="type == 'user_name'">
          <div style="width: 33%" v-html="item.userName"></div>
          <div style="width: 33%">{{ item.departmentName }}</div>
          <div style="width: 33%">{{ item.tops }}</div>
        </li>
      </ul>
    </section>

    <div>
      <div class="block">
        <el-pagination
          :pager-count="5"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <span style="margin-top: 7px">共{{ total }}条</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetaileBuriedPointJournal } from "../../../api/api";
import { formatDate } from "../../../utils/date";

export default {
  props: {
    agentid: String,
    endTime: String,
    startTime: String,
    selectTime: String,
    type: String,
    header: Array,
    showDetailed: Object,
    val: Object,
  },
  name: "detaile",
  data() {
    return {
      searchLoading:false,
      dataList: [],
      detaileData: [],
      value: "",
      currentPage3: 1,
      total: 0,
      flag: true,
      orderByClause: "desc",
    };
  },
  watch: {
    selectTime(newValue, oldValue) {
      if (this.type == "report_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          "",
          this.value,
          this.orderByClause
        );
      } else if (this.type == "user_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          this.value,
          "",
          this.orderByClause
        );
      }
    },
  },
  created() {
    console.log(this.type);
    if (this.type == "report_name") {
      this.getDetaileBuriedPointJournal(
        this.type,
        "",
        this.value,
        this.orderByClause
      );
    } else if (this.type == "user_name") {
      this.getDetaileBuriedPointJournal(
        this.type,
        this.value,
        "",
        this.orderByClause
      );
    }
    console.log(this.detaileData);
  },
  methods: {
    highlights() {
      const search = this.value;
      this.detaileData = this.detaileData.map((item) => {
        for (let key in item) {
          if (this.type == "report_name") {
            if (key === "reportName") {
              let replaceReg = new RegExp(search, "g"); // 匹配关键字正则
              let replaceString =
                '<span id="highlights-text">' + search + "</span>"; // 高亮替换v-html值
              item[key] = item[key].replace(replaceReg, replaceString); // 开始替换
            }
          }
          if (this.type == "user_name") {
            console.log(key);
            if (key === "userName") {
              let replaceReg = new RegExp(search, "g"); // 匹配关键字正则
              let replaceString =
                '<span id="highlights-text">' + search + "</span>"; // 高亮替换v-html值
              item[key] = item[key].replace(replaceReg, replaceString); // 开始替换
            }
          }
        }
        console.log(item);
        return item;
      });
    },

    getFocus() {
      this.$emit("closeSelecTime");
    },
    clearSearch() {
      this.searchLoading = true
      this.value = "";
      if (this.type == "report_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          "",
          this.value,
          this.orderByClause
        );
      } else if (this.type == "user_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          this.value,
          "",
          this.orderByClause
        );
      }
    },
    sort() {
      this.flag = !this.flag;
      if (this.flag) {
        this.orderByClause = "desc";
      } else {
        this.orderByClause = "asc";
      }
      console.log(this.orderByClause);
      if (this.type == "report_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          "",
          this.value,
          this.orderByClause
        );
      } else if (this.type == "user_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          this.value,
          "",
          this.orderByClause
        );
      }
    },
    async getDetaileBuriedPointJournal(
      type,
      userName,
      reportName,
      orderByClause
    ) {
      try {
        let data = {
          agentId: String(this.agentid),
          groupColunm: type,
          startTime: this.startTime,
          likeUserName: userName,
          likeReportName: reportName,
          endTime: this.endTime,
          orderByClause: orderByClause,
          pageSize: 10,
          page: this.val.val,
        };
        let result = await getDetaileBuriedPointJournal(data);
        console.log("result");
        this.total = JSON.parse(result).total;
        console.log(JSON.parse(result).list);
        this.dataList = [];
        this.dataList = JSON.parse(result).list;
        this.dataList.map((m) => {
          m.visitTime = formatDate(
            new Date(m.visitTime),
            "yyyy-MM-dd hh:mm:ss"
          );
        });
        this.detaileData = this.dataList;
        this.highlights();
        this.searchLoading = false
      } catch (e) {
        console.log(e);
      }
    },

    closeDetailed() {
      this.showDetailed.showDetailed = false;
      this.$emit("getAccessTrends");
    },
    search() {
      this.searchLoading = true
      this.$emit("closeSelecTime");
      console.log(this.value);
      if (this.type == "report_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          "",
          this.value,
          this.orderByClause
        );
      } else if (this.type == "user_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          this.value,
          "",
          this.orderByClause
        );
      }
      this.val.val = 1;
    },
    handleSizeChange(val) {
      this.$emit("closeSelecTime");
      console.log(`每页 ${val} 条`);
      this.val.val = val;
      if (this.type == "report_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          "",
          this.value,
          this.orderByClause
        );
      } else if (this.type == "user_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          this.value,
          "",
          this.orderByClause
        );
      }
    },
    handleCurrentChange(val) {
      this.$emit("closeSelecTime");
      this.val.val = val;
      console.log(`当前页: ${val}`);
      if (this.type == "report_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          "",
          this.value,
          this.orderByClause
        );
      } else if (this.type == "user_name") {
        this.getDetaileBuriedPointJournal(
          this.type,
          this.value,
          "",
          this.orderByClause
        );
      }
    },
  },
};
</script>

<style scoped lang="less">
.detailedBtn {
  position: absolute;
  left: 10px;
  top: 14px;
  font-weight: 700;
  font-size: 18px;
  background-color: #0086f9;
  color: white;
  /*float: right;*/
  width: 90px;
  height: 30px;
  border-radius: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 1px #0086f9;
}
.detaile {
  > .searchHeader {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > .showTime {
      width: 100%;
    }
    > .searchOuter {
      padding: 0 20px;
      box-sizing: border-box;
      margin-top: 10px;
      display: flex;
      width: 100%;
      justify-content: center;
      > .search {
        display: flex;
        width: 400px;
        height: 50px;
        > .icon {
          font-size: 26px;
          color: #8c939d;
          text-align: center;
          line-height: 36px;
          box-sizing: border-box;
          border: 1px solid #8c939d;
          border-radius: 18px 0px 0px 18px;
          width: 36px;
          height: 36px;
          border-right: none;
        }
        > .word {
          box-sizing: border-box;
          height: 36px;
          border: 1px solid #8c939d;
          flex-grow: 1;
          border-radius: 0px 18px 18px 0px;
          border-left: none;
          > input {
            min-width: 270px;
            border-radius: 0 17px 17px 0;
            line-height: 34px;
            font-size: 16px;
            height: 33px;
            border: none;
            outline: none;
          }
        }
        > .searchBtn {
          cursor: pointer;
          color: #0086f9;
          font-weight: 700;
          padding-left: 14px;
          width: 40px;
          height: 36px;
          line-height: 36px;
          font-size: 20px;
        }
      }
    }
  }
  @media only screen and (min-width: 762px) {
    > .searchHeader {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > .showTime {
        width: 50%;
      }
      > .searchOuter {
        display: flex;
        width: 50%;
        justify-content: center;
        > .search {
          display: flex;
          width: 400px;
          height: 50px;
          > .icon {
            font-size: 26px;
            color: #8c939d;
            text-align: center;
            line-height: 34px;
            box-sizing: border-box;
            border: 1px solid #8c939d;
            border-radius: 18px 0px 0px 18px;
            width: 36px;
            height: 36px;
            border-right: none;
          }
          > .word {
            box-sizing: border-box;
            height: 36px;
            border: 1px solid #8c939d;
            flex-grow: 1;
            border-radius: 0px 18px 18px 0px;
            border-left: none;
          }
          > .searchBtn {
            color: #0086f9;
            font-weight: 700;
            padding-left: 14px;
            width: 40px;
            height: 18px;
            line-height: 36px;
            font-size: 20px;
          }
        }
      }
    }
  }
  > .header {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 10px 10px 20px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
    box-shadow: 1px 1px 1px #ccc;
    > div {
      text-align: center;
      min-width: 90px;
    }
  }
  > .contentArea {
    padding: 0 10px;
    height: 396px;
  }
  .content {
    margin-top: 10px;
    > li {
      height: 30px;
      display: flex;
      justify-content: space-around;
      .name {
        min-width: 120px;
      }
      > div {
        text-align: center;
        min-width: 90px;
      }
      > div:nth-child(1) {
        padding-left: 10px;
      }
      > div:last-child {
        box-sizing: border-box;
        padding-right: 20px;
      }
    }
  }
}
.showTime {
  color: #747a98;
  margin-top: 12px;
  text-align: center;
}
</style>
<style>
.detaile .block {
  padding-right: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.detaile .number {
  min-width: 5vw;
}
.detaile .el-pagination__jump {
  margin: 0;
  background-color: #ffffff;
}
.detaile .el-pagination button {
  padding: 0;
}
.detaile .el-pagination__editor.is-in-pagination {
  width: 30px !important;
}
.detaile .el-pager > li {
  padding: 0;
  min-width: 17px;
}
#highlights-text {
  color: #ff5134 !important;
}
</style>