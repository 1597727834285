<template>
    <div class="accessStatistics"
         v-loading="!show"
         v-touch:swiperight="rightSlide"
         id="accessStatistics">
        <div class="accessStatisticsHeader" style="position: relative">
            {{title}}
        </div>
        <div class="outerTabs">
            <div class="tabs">
                <div
                        v-for="(item, index) in timeList"
                        :key="index"
                        :class="time == item ? 'tabsList active' : 'tabsList'"
                        @click="changeTime(item)"
                >
                    {{ item }}
                </div>
            </div>
        </div>
        <div
                class="block"
                v-show="showTimeSelect"
                style="position: absolute; z-index: 1000"
        >
            <el-date-picker
                    v-model="value2"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
            >
            </el-date-picker>
        </div>
        <section>
            <detaile
                    @getAccessTrends="getAccessTrends"
                    @closeSelecTime="closeSelecTime"
                    :val="val"
                    :agentid="String(agentid)"
                    :selectTime="selectTime"
                    :startTime="startTime"
                    :endTime="endTime"
                    :dataList="detaileArr"
                    :type="type"
                    :header="header"
            ></detaile>
        </section>
    </div>
</template>

<script>
    import { formatDate } from "../../utils/date";
    import detaile from "./components/detaile";
    import {getSignature } from "@/api/api";

    export default {
        name: "accessStatistics",
        components: {
            detaile,
        },
        data() {
            return {
                title:'',
                show: false,
                agentid: "",
                appid: "",
                code: "",
                val: {
                    val: 1,
                },
                startTime: formatDate(new Date(), "yyyy-MM-dd") + " " + "00:00:00",
                endTime: formatDate(new Date(), "yyyy-MM-dd hh:mm:ss"),
                showTimeSelect: false,
                value2: "",
                timeList: ["今天", "昨天", "近七天", "近一个月", "自定义"],
                selectTime: formatDate(new Date(), "yyyy-MM-dd"),
                time: "今天",
                type: "",
                header: [],
                detaileArr: [],
                userId: "",
                orgId: "",
            };
        },
        directives: {
            //滑动指令
            touch: {
                bind: function (el, binding, vnode) {
                    var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
                    var timeOutEvent = 0;
                    var direction = "";
                    //滑动处理
                    var startX, startY;

                    //返回角度
                    function GetSlideAngle(dx, dy) {
                        return (Math.atan2(dy, dx) * 180) / Math.PI;
                    }

                    //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
                    function GetSlideDirection(startX, startY, endX, endY) {
                        var dy = startY - endY;
                        var dx = endX - startX;
                        var result = 0;

                        //如果滑动距离太短
                        if (Math.abs(dx) < 180 && Math.abs(dy) < 180) {
                            return result;
                        }

                        var angle = GetSlideAngle(dx, dy);
                        if (angle >= -45 && angle < 45) {
                            result = "swiperight";
                        } else if (angle >= 45 && angle < 135) {
                            result = "swipeup";
                        } else if (angle >= -135 && angle < -45) {
                            result = "swipedown";
                        } else if (
                            (angle >= 135 && angle <= 180) ||
                            (angle >= -180 && angle < -135)
                        ) {
                            result = "swipeleft";
                        }
                        return result;
                    }

                    el.addEventListener(
                        "touchstart",
                        function (ev) {
                            startX = ev.touches[0].pageX;
                            startY = ev.touches[0].pageY;

                            //判断长按
                            timeOutEvent = setTimeout(() => {
                                timeOutEvent = 0;
                                if (touchType === "press") {
                                    binding.value();
                                }
                            }, 500);
                        },
                        false
                    );

                    el.addEventListener("touchmove", function (ev) {
                        clearTimeout(timeOutEvent);
                        timeOutEvent = 0;
                    });

                    el.addEventListener(
                        "touchend",
                        function (ev) {
                            var endX, endY;
                            endX = ev.changedTouches[0].pageX;
                            endY = ev.changedTouches[0].pageY;
                            direction = GetSlideDirection(startX, startY, endX, endY);

                            clearTimeout(timeOutEvent);

                            switch (direction) {
                                case 0:
                                    break;
                                case "swipeup":
                                    if (touchType === "swipeup") {
                                        binding.value();
                                    }
                                    break;
                                case "swipedown":
                                    if (touchType === "swipedown") {
                                        binding.value();
                                    }
                                    break;
                                case "swipeleft":
                                    if (touchType === "swipeleft") {
                                        binding.value();
                                    }
                                    break;
                                case "swiperight":
                                    if (touchType === "swiperight") {
                                        binding.value();
                                    }
                                    break;
                                default:
                            }
                        },
                        false
                    );
                },
            },
        },
        methods: {
            rightSlide() {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (JSON.parse(sessionStorage.getItem("from")) == "/") {
                    window.wx.closeWindow();
                }
            },
            async getSignature(agentid, appid) {
                let url = window.location.href.split("#")[0];
                try {
                    let data = {
                        url: url,
                        agentId: agentid,
                    };
                    let res = await getSignature(data);
                    let result = JSON.parse(res);
                    wx.agentConfig({
                        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        corpid: appid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
                        timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
                        nonceStr: String(result.nonce_str), // 必填，生成签名的随机串
                        signature: String(result.signature), // 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: [
                            "selectExternalContact",
                            "onHistoryBack",
                            "closeWindow",
                            "hideOptionMenu",
                            "onMenuShareAppMessage",
                        ], //必填，传入需要使用的接口名称
                        success: function (res) {
                            // alert(window.location.href)
                            wx.onHistoryBack(function () {
                                if (JSON.parse(sessionStorage.getItem("from")) == "/") {
                                    wx.closeWindow();
                                }
                                return true;
                            });
                            wx.onMenuShareAppMessage({
                                title: "舆情报告", // 分享标题
                                desc: "随时查阅舆情报告，及时掌握关键信息。 ", // 分享描述
                                link: window.location.href, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                                imgUrl:
                                    "http://inews5.xlmediawatch.com/reportFile/asslogjs/1047/lnewsImg.png", // 分享图标
                                success: function () {
                                    // 用户确认分享后执行的回调函数
                                },
                                cancel: function () {
                                    // 用户取消分享后执行的回调函数
                                },
                            });
                        },
                        fail: function (res) {
                            if (res.errMsg.indexOf("function not exist") > -1) {
                                alert("版本过低请升级");
                            }
                        },
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            closeSelecTime() {
                this.showTimeSelect = false;
            },
            showReportDetailed() {
                this.title = '报告访问详情'
                this.detaileArr = [];
                this.type = "report_name";
                this.header = ["报告名称", "模块名称", "发布时间", "访问次数"];
                this.show = true;
            },
            showUserDetailed() {
                this.title = '用户访问详情'
                this.detaileArr = [];
                this.type = "user_name";
                this.header = ["姓名", "部门", "报告访问次数"];
                this.show = true;
            },
            async changeTime(time) {
                this.time = time;
                if (time == "今天") {
                    this.val.val = 1;
                    this.showTimeSelect = false;
                    this.startTime =
                        formatDate(new Date(), "yyyy-MM-dd") + " " + "00:00:00";
                    this.endTime = formatDate(new Date(), "yyyy-MM-dd hh:mm:ss");
                    this.selectTime = formatDate(new Date(), "yyyy-MM-dd");
                } else if (time == "昨天") {
                    this.val.val = 1;
                    this.showTimeSelect = false;
                    this.startTime = formatDate(
                        new Date(
                            new Date(
                                (formatDate(new Date(), "yyyy-MM-dd") + " " + "00:00:00").replace(
                                    /-/g,
                                    "/"
                                )
                            ).valueOf() - 86400000
                        ),
                        "yyyy-MM-dd hh:mm:ss"
                    );
                    this.endTime = formatDate(
                        new Date(
                            new Date(
                                (formatDate(new Date(), "yyyy-MM-dd") + " " + "00:00:00").replace(
                                    /-/g,
                                    "/"
                                )
                            ).valueOf() - 1
                        ),
                        "yyyy-MM-dd hh:mm:ss"
                    );
                    this.selectTime = formatDate(
                        new Date(new Date().valueOf() - 86400000),
                        "yyyy-MM-dd"
                    );
                } else if (time == "近七天") {
                    this.val.val = 1;
                    this.showTimeSelect = false;
                    this.startTime =
                        formatDate(
                            new Date(new Date().valueOf() - 86400000 * 6),
                            "yyyy-MM-dd"
                        ) +
                        " " +
                        "00:00:00";
                    this.endTime = formatDate(new Date(), "yyyy-MM-dd hh:mm:ss");
                    this.selectTime =
                        formatDate(
                            new Date(new Date().valueOf() - 86400000 * 6),
                            "yyyy-MM-dd"
                        ) +
                        " - " +
                        formatDate(new Date(), "yyyy-MM-dd");
                } else if (time == "近一个月") {
                    this.val.val = 1;
                    this.showTimeSelect = false;
                    this.startTime =
                        formatDate(
                            new Date(new Date().valueOf() - 86400000 * 29),
                            "yyyy-MM-dd"
                        ) +
                        " " +
                        "00:00:00";
                    this.endTime = formatDate(new Date(), "yyyy-MM-dd hh:mm:ss");
                    this.selectTime =
                        formatDate(
                            new Date(new Date().valueOf() - 86400000 * 29),
                            "yyyy-MM-dd"
                        ) +
                        " - " +
                        formatDate(new Date(), "yyyy-MM-dd");
                } else if (time == "自定义") {
                    this.showTimeSelect = !this.showTimeSelect;
                }
            },
            async getLoginMsg() {
                let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                if (userInfo) {
                    let agentid = JSON.parse(sessionStorage.getItem("agentid"));
                    this.agentid = agentid;
                    let appid = JSON.parse(sessionStorage.getItem("appid"));
                    this.appid = appid;
                    console.log(agentid);
                    if (userInfo.orgId.includes(this.orgId) && userInfo.roleIds == 2) {

                        await this.getSignature(agentid, appid);
                        if(this.type == 'report_name'){
                            this.title='报告统计详情'
                            await this.showReportDetailed()
                        }else if(this.type == 'user_name'){
                            this.title='用户统计详情'
                            await this.showUserDetailed()
                        }
                    } else {
                        this.$router.push({ path: "/noPermissions" });
                    }
                } else {
                    this.$router.push({ path: "/noPermissions" });
                }
            },
        },
        watch: {
            value2(newValue, oldValue) {
                if (newValue.length == 2) {
                    this.startTime = formatDate(
                        new Date(this.value2[0]),
                        "yyyy-MM-dd hh:mm:ss"
                    );
                    this.endTime =
                        formatDate(new Date(this.value2[1]), "yyyy-MM-dd") + " 23:59:59";
                    this.selectTime =
                        formatDate(new Date(this.value2[0]), "yyyy-MM-dd") +
                        " - " +
                        formatDate(new Date(this.value2[1]), "yyyy-MM-dd");
                    this.showTimeSelect = false;
                }
            },
        },
        async created() {
            this.type = this.$route.query.type
            await this.getLoginMsg();
        },
    };
</script>

<style scoped lang="less">
    .accessStatistics {
        position: relative;
        padding: 10px 0;
        background-color: white;
        /*height: 96%;*/
        /*overflow: hidden;*/
        overflow-y: auto;
        min-height: 100vh;
        box-sizing: border-box;
        .detailedBtn {
            /*font-weight: 700;*/
            font-size: 14px;
            background-color: #0086f9;
            color: white;
            float: right;
            width: 80px;
            height: 30px;
            border-radius: 15px;
            outline: none;
            border: none;
            cursor: pointer;
            box-shadow: 1px 1px 1px #0086f9;
        }
        .top10,
        .access {
            width: 100%;
            padding: 8px 0;
            height: auto;
        }
        .access {
            padding: 0 20px;
            box-sizing: border-box;
        }
        .charts {
            box-sizing: border-box;
            padding: 0 20px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @media only screen and (min-width: 762px) {
            .top10 {
                height: 30px;
                width: 49%;
            }
        }
        .echartTitle {
            font-weight: 700;
            font-size: 18px;
            color: #43546c;
        }
        .echarts {
            margin-top: 10px;
            border: 1px solid #ebebeb;
            box-shadow: 1px 2px 2px #ebebeb;
        }
        .accessStatisticsHeader {
            font-size: 24px;
            color: #2b314b;
            font-weight: 700;
            width: 100%;
            text-align: center;
        }
        .outerTabs {
            margin-top: 20px;

            width: 100%;
            border-bottom: 1px solid #d7d7d8;
            border-top: 1px solid #d7d7d8;
            box-shadow: 1px 1px 1px #d7d7d8;
            .tabs {
                height: 40px;
                background-color: white;
                width: 100%;
                max-width: 768px;
                display: flex;
                .tabsList {
                    cursor: pointer;
                    line-height: 34px;
                    font-size: 14px;
                    color: #43546c;
                    text-align: center;
                    width: 20%;
                    border: 3px solid transparent;
                }
                > .active {
                    border-bottom-color: #3ea3f9;
                    color: #3ea3f9;
                }
            }
        }

        .showTime {
            color: #747a98;
            margin-top: 12px;
            text-align: center;
        }
    }
</style>
<style lang="less">
    .el-picker-panel__body-wrapper {
        width: 100%;
        overflow: hidden;
    }
    .el-picker-panel.el-date-range-picker.el-popper {
        /*top: 96px!important;*/
    }
    .el-picker-panel__body-wrapper > .el-picker-panel__body {
        position: relative;
        top: 10px;
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        min-width: 0 !important;
        .el-date-range-picker__content.is-left {
            /*padding: 0;*/
            width: 50%;
        }
        .el-date-range-picker__content.is-right {
            /*padding: 0;*/
            width: 50%;
        }
    }
    @media only screen and (max-width: 762px) {
        .el-picker-panel.el-date-range-picker.el-popper {
            width: 100%;
            left: 0 !important;
            top: 95px !important;
            display: flex;
            justify-content: center;
            > .el-picker-panel__body-wrapper > .el-picker-panel__body {
                display: flex;
                flex-wrap: wrap;
                .el-date-range-picker__content.is-left {
                    /*max-width: 400px;*/
                    width: 100%;
                    border-right: 0;
                }
                .el-date-range-picker__content.is-right {
                    /*max-width: 400px;*/
                    width: 100%;
                }
            }
        }
    }
</style>
