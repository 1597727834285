export const getYearMonthDay = (value) => {
    const date = value ? new Date(value) : new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return [year, month, day];
};

export const getCurrentMonthLastDay = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

export const getPrevMonthLastDay = (year, month) => {
    return new Date(year, month, 0).getDate();
};
export const cloneDate = (date) => {
    return new Date(date.getTime());
};
export const formatDate = (date, format) => {
    const seperator1 = "-";
    const seperator2 = ":";
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if(format == 'yyyy-MM-dd hh:mm:ss'){
        return date.getFullYear() + seperator1 + month + seperator1 + strDate + " " +
            (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + seperator2 +
            (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + seperator2 +
            (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
    }else if(format == 'yyyy-MM-dd'){
        return date.getFullYear() + seperator1 + month + seperator1 + strDate;
    }
}
export const formatDateForTimestamp = (date, format) => {
    return formatDate(new Date(date),format)
}
